import { useMemo } from 'react';
import { not } from '@tellurian/ts-utils';
import useListOfItemsMultiSelect, {
  OnItemToggleFn,
  UseListOfItemsMultiSelectParams,
} from '../listOfItems/useListOfItemsMultiSelect';
import { Option } from '../../lib';
import useEventCallback from '../../../lettuce/utils/useEventCallback';

type UseListOfOptionsMultiSelectParams<T extends Option<string>> = Omit<
  UseListOfItemsMultiSelectParams<T, T['value']>,
  'onItemToggle'
> & {
  isSelectAllOption?: (option: T, index: number) => boolean;
};

const useListOfOptionsMultiSelect = <
  T extends Readonly<Option<string>> = Readonly<Option<string>>,
>({
  items,
  isSelectAllOption,
  ...rest
}: UseListOfOptionsMultiSelectParams<T>) => {
  const onItemToggle: OnItemToggleFn<T> = useEventCallback(
    ({ item, index, setSelection, getSelection, toggleItemSelected }) => {
      if (isSelectAllOption?.(item, index)) {
        const allItems = items.filter(not(isSelectAllOption));
        const allSelected = getSelection().length === allItems.length;
        setSelection(allSelected ? [] : allItems);
      } else {
        toggleItemSelected(item);
      }
    },
  );

  return useListOfItemsMultiSelect<T, string>({ items, onItemToggle, ...rest });
};

export default useListOfOptionsMultiSelect;

export type OptionWithMaybeSelectAll = Readonly<
  Option<string> & {
    kind?: 'select all';
  }
>;

const SelectAllItem: Readonly<Option<'Select All'> & { kind: 'select all' }> = {
  text: 'Select All',
  value: 'Select All',
  kind: 'select all',
};

export const useListOfOptionsMultiSelectAll = ({
  items: itemList,
  ...rest
}: Omit<UseListOfOptionsMultiSelectParams<OptionWithMaybeSelectAll>, 'isSelectAllOption'>) => {
  const items: Option<string>[] = useMemo(() => [SelectAllItem, ...itemList], [itemList]);
  return useListOfOptionsMultiSelect({
    items,
    isSelectAllOption: item => item['kind'] === SelectAllItem.kind,
    ...rest,
  });
};
