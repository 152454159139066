import React, { HTMLAttributes, ReactElement, Ref } from 'react';
import clsx from 'clsx';
import { AnyObject } from '@tellurian/ts-utils';
import _ from 'lodash';
import style from './ListOfItems.module.css';

// P typically refers to a list of globally accessible props which should typically be "plugins"
// (e.g. actionable, focusable, selectable etc)
export type RenderItemProps<T, P extends AnyObject = AnyObject> = P & {
  item: T;
  index: number;
  id?: string;
};

const ListPropNames = [
  'className',
  'aria-labelledby',
  'onMouseDown',
  'onKeyDown',
  'tabIndex',
  'onBlur',
  'onFocus',
  'id',
  'style',
] as const;
export type ListOfItemsProps<T, P extends AnyObject = AnyObject> = P &
  Pick<HTMLAttributes<HTMLUListElement>, (typeof ListPropNames)[number]> & {
    id?: string;
    items: readonly T[];
    getItemId?: (item: T, index: number) => string;
    RenderItem: React.FC<RenderItemProps<T, P>>;
    getItemKey?: (item: T, index: number) => string;
    RenderBeforeItem?: React.FC<RenderItemProps<T, P>>;
    RenderAfterItem?: React.FC<RenderItemProps<T, P>>;
    focus?: boolean;
    role?: 'none' | 'listbox' | 'list';
    as?: 'ul' | 'dl';
  };

const ListOfItems = React.forwardRef<HTMLUListElement, ListOfItemsProps<unknown>>(
  // @ts-expect-error Spurious error
  function ListOfItems<T>(
    {
      items,
      RenderItem,
      getItemKey = (item, index) => `item ${index}`,
      getItemId,
      className,
      RenderBeforeItem,
      RenderAfterItem,
      tabIndex: tabIndexProp,
      role = 'listbox',
      as: ListComponent = 'ul',
      ...rest
    }: ListOfItemsProps<T>,
    ref: React.Ref<HTMLUListElement>,
  ) {
    const liProps = _.omit(rest, ListPropNames);
    // If a keyDown handler is provided, then default the tabIndex to 0 (assume the list is intended to be focusable)
    const tabIndex = rest.onKeyDown ? (tabIndexProp ?? 0) : tabIndexProp;

    return (
      <ListComponent
        {..._.pick(rest, ListPropNames)}
        ref={ref}
        className={clsx(style.list, className)}
        role={role === 'none' ? undefined : role}
        tabIndex={tabIndex}
      >
        {RenderBeforeItem || RenderAfterItem
          ? items.map((item, index) => {
              const props = { item, index, ...liProps };
              return (
                <React.Fragment key={getItemKey(item, index)}>
                  {RenderBeforeItem && <RenderBeforeItem {...props} />}
                  <RenderItem {...props} id={getItemId?.(item, index)} />
                  {RenderAfterItem && <RenderAfterItem {...props} />}
                </React.Fragment>
              );
            })
          : items.map((item, index) => (
              <RenderItem key={getItemKey(item, index)} item={item} index={index} {...liProps} />
            ))}
      </ListComponent>
    );
  },
);

export default React.memo(ListOfItems) as <T, P extends AnyObject = AnyObject>(
  props: ListOfItemsProps<T, P>,
  ref?: Ref<HTMLUListElement>,
) => ReactElement;
