import { ApolloClient, ApolloLink, fromPromise, HttpLink } from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { GraphQLError } from 'graphql';
import { Maybe } from '@tellurian/ts-utils';
import { GetPageErrorDocument, PageErrorCode } from '../generated/graphql';
import introspectionQueryResultData from '../generated/fragmentTypes.json';
import { trackError } from '../utils/errorTracking';
import { maybeReloadPage } from '../components/lib';
import { not } from '../components/SourceDataSelect/lib';
import {
  dispatchHttpErrorEvent,
  dispatchPageErrorEvent,
  fromHttpStatus,
  PageError,
} from '../components/lettuce/components/PageErrorNotifications/lib';
import { errorCodeByHttpStatus } from '../components/errorHandling/errorMessages';
import typePolicies from './typePolicies';
import logger from './logger';
const { possibleTypes } = introspectionQueryResultData;
export { possibleTypes };

const graphQLUrl = process.env.REACT_APP_GRAPHQL_URL;
const restApiUrl = process.env.REACT_APP_REST_API_URL;
const remaUserProvisioningApiUrl = process.env.REACT_APP_REMA_USER_PROVISIONING_API_BASE_URL;

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const ignoreErrorMessages = [
  'Unable to resolve Account with id', // usually a routing issue and can be caused by the user (Bad URL)
  '503: Service Unavailable', // hoping this would be logged by monitoring on the service itself and this is probably redundant
  '401: Unauthorized',
];

const isExpiredTokenMessage = (message: string) => /Jwt expired at/.test(message);

const ignoreError = (graphQLError: GraphQLError): boolean => {
  const { message } = graphQLError;
  if (ignoreErrorMessages.some(ignore => message.includes(ignore))) {
    return true;
  }

  // Read: "Ignore error whenever a connector configuration id cannot be resolved when attempting to retrieve all connector configurations."
  if (message.startsWith('Unable to resolve ConnectorConfiguration with id')) {
    if (
      graphQLError.path?.[0] === 'account' &&
      graphQLError.path?.[1] === 'connectorConfigurations'
    ) {
      return true;
    }
  }

  return false;
};

function create(initialState: NormalizedCacheObject = {}, errorHandling2Enabled = true) {
  const cache = new InMemoryCache({ possibleTypes, typePolicies }).restore(initialState);

  let isRefetchingToken = false;
  let hasLastRefetchedTokenAt = 0;

  const authLink = setContext((_, { headers }) => {
    if (headers?.authorization !== undefined) {
      return { headers };
    }

    const user = firebase.auth().currentUser;
    // This is legitimate on our pages which do not require authentication
    if (user === null) {
      return { headers };
    }

    return user.getIdToken(false).then(
      token => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      },
      () => {
        trackError(
          `Failed to extract idToken from firebase user object. Bearer token cannot be included.`,
        );
      },
    );
  });

  const graphQLLink = new HttpLink({
    credentials: 'include',
    uri: graphQLUrl,
  });

  const errorLink = onError(({ operation, graphQLErrors, networkError, forward }) => {
    const context = operation.getContext();
    const ignoreNetworkError = context.ignoreNetworkError || false;

    if (graphQLErrors) {
      // If the token has expired, it should normally be auto-refreshed on user.getIdToken
      // however there seem to be instances where this does not happen. The following will attempt to
      // force-refresh the token and retry the request.
      const hasExpiredToken = graphQLErrors.some(e => isExpiredTokenMessage(e.message));
      const user = firebase.auth().currentUser;
      if (hasExpiredToken && user) {
        logger.info(`Expired token detected for graphql op ${operation.operationName}`, {
          user: { email: user.email, displayName: user.displayName },
        });
        isRefetchingToken = true;
        const promise = user
          .getIdToken(Date.now() - hasLastRefetchedTokenAt > 5000)
          .then(token => {
            const prevHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...prevHeaders,
                authorization: token ? `Bearer ${token}` : '',
              },
            });
            logger.info(`Refetched token for graphql op ${operation.operationName}`, {
              user: { email: user.email, displayName: user.displayName },
            });
          })
          .finally(() => {
            isRefetchingToken = false;
            hasLastRefetchedTokenAt = Date.now();
          });

        return fromPromise(promise).flatMap(() => forward(operation));
      }

      const { shouldIgnoreGraphQlError } = context;
      const filteredErrors = graphQLErrors.filter(not(ignoreError));
      filteredErrors.forEach(err => {
        const { message, locations, path, extensions } = err;
        if (typeof shouldIgnoreGraphQlError === 'function' && shouldIgnoreGraphQlError(err)) {
          return;
        }

        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

        if (errorHandling2Enabled) {
          dispatchHttpErrorEvent(extensions?.http_status as Maybe<number>);
        } else {
          const statusCode = extensions?.statusCode as Maybe<number>;
          cache.writeQuery({
            query: GetPageErrorDocument,
            data: {
              pageErrorCode: statusCode
                ? errorCodeByHttpStatus(statusCode)
                : PageErrorCode.UnknownError,
            },
          });
        }

        if (filteredErrors.length) {
          // Reload the page if this is not the latest version of core-ui - it is probably the cause
          // of the graphql error (endpoint might no longer be available or has changed and is now
          // incompatible with what the FE expects).
          maybeReloadPage(true).then(pageWillReload => {
            if (!pageWillReload) {
              // Only track this error if page does not reload due to a core-ui version mismatch
              trackError(message, {
                filePath: 'GraphQL Error',
                lineNumber: 0,
                functionName: operation.operationName,
                locations,
                path,
              });
            }
          });
        }
      });
    }
    if (networkError && !ignoreNetworkError) {
      const statusCode = networkError['statusCode'];
      if (errorHandling2Enabled) {
        dispatchPageErrorEvent(statusCode ? fromHttpStatus(statusCode) : PageError.NetworkError);
      } else {
        cache.writeQuery({
          query: GetPageErrorDocument,
          data: {
            pageErrorCode: statusCode
              ? errorCodeByHttpStatus(statusCode)
              : PageErrorCode.NetworkError,
          },
        });
      }

      console.log(`[Network error]: ${networkError}`);
    }
  });

  const retryLink = new RetryLink({
    attempts: (count: number, operation, error) => {
      if (error.statusCode === 401 && (isRefetchingToken || count > 1)) {
        return false;
      }

      if (count >= 3) {
        return false;
      }

      return !!error && !error.statusCode;
    },
    delay: {
      initial: 500,
      max: 4000,
      jitter: true,
    },
  });

  const restLink = new RestLink({
    credentials: 'include',
    endpoints: {
      restApiUrl: restApiUrl || '',
      powerBiApi: 'https://api.powerbi.com',
      remaUserProvisioningApi: remaUserProvisioningApiUrl || '',
    },
    uri: restApiUrl,
  });

  return new ApolloClient<NormalizedCacheObject>({
    cache,
    link: ApolloLink.from([authLink, errorLink, retryLink, restLink, graphQLLink]),
  });
}

export default function initApollo(
  initialState: NormalizedCacheObject = {},
  toastNotificationsEnabled = true,
) {
  if (!apolloClient) {
    apolloClient = create(initialState, toastNotificationsEnabled);
  }

  return apolloClient;
}
